/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import { FormBuilder } from '@nfs-react/components';
import { FormField } from '../styles';

const HiddenInput = ({ id, value }) => {
  return (
    <FormField data-id={id} area={id}>
      <HiddenInputStyle defaultValues={value} type='text' name={id} />
    </FormField>
  );
};

const HiddenInputStyle = styled(FormBuilder.Input)`
  display: none;
`;

export default HiddenInput;
