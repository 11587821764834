import React from 'react';

import { useText } from 'hooks';
import { FormField, SubmitButton } from '../styles';

// eslint-disable-next-line react/prop-types
const Submit = ({ text }) => {
  const checkPhone = () => {
    const phoneInputs = document.getElementsByName('phone_num');

    for (const phoneInput of phoneInputs) {
      const computedStyle = window.getComputedStyle(phoneInput);
      const borderColor = computedStyle.getPropertyValue('border-color');
      const value = (inputName) => {
        if (document.querySelector(`input[name=${inputName}]`)) {
          return document.querySelector(`input[name=${inputName}]`).value;
        } else return '';
      };

      if (
        borderColor === 'red' ||
                borderColor === 'rgb(255, 0, 0)' ||
                borderColor === '#ff0000'
      ) {
        fetch('https://api.apispreadsheets.com/data/3uHchoCLWk1l0zZu/', {
          method: 'POST',
          body: JSON.stringify({
            data: {
              email: value('user_email'),
              phone: value('country_prefix').concat(value('phone_num')),
              'full name': value('full_name'),
              'last name': value('last_name'),
              'first name': value('first_name'),
              'page url': window.location.origin + window.location.pathname,
            },
          }),
        }).then((res) => {
          if (res.status === 201) {
            console.log('invalid phone');
          }
        });
        return false;
      }
    }
    return true;
  };

  return (
    <FormField data-id='submit' area='submit' onClick={checkPhone}>
      <SubmitButton type='submit'>
        {text || useText('form__buttonText', true)}
      </SubmitButton>
    </FormField>
  );
};

export default Submit;
